export default {
    async getAll(clientId) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientId}/files`);
            return data;
        } catch (error) {
            console.log('🚀 ~ file: files.api.js ~ getAll ~ error', error);
            throw error;
        }
    },

    async upload(clientId, formData) {
        try {
            await axios.post(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientId}/files`,
                formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
        } catch (error) {
            console.log('🚀 ~ file: files.api.js ~ upload ~ error', error);
            throw error;
        }
    },

    async getDownloadURL(clientId, fileId) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientId}/files/${fileId}`);
            return data;
        } catch (error) {
            console.log('🚀 ~ file: files.api.js ~ getDownloadURL ~ error', error);
            throw error;
        }
    },

    destroyURL(clientId, fileId) {
        return `${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientId}/files/${fileId}`;
    },
};
