<template>
    <div class="card flex flex-col flex-1 card-f-h">
        <ConfirmationModal @success="actionSuccess" />

        <ModalUpload
            :visible="modalUploadFileVisible"
            :clientId="clientId"
            @refresh="actionSuccess"
            @close="modalUploadFileVisible = false"
        />

        <header class="flex items-center justify-between h-10 border-b border-solid border-gray-200 pb-4 mb-5">
            <h1>{{ $t('employee_details.files') }}</h1>
            <el-button type="primary" @click="showUploadModal">
                {{ $t('common.add') }}
            </el-button>
        </header>

        <el-table
            v-if="filesData.length > 0"
            v-loading="$wait.is('loading.files')"
            :data="filesData"
            style="width: 100%"
            element-loading-spinner="el-custom-spinner"
        >
            <el-table-column prop="name" :label="$t('employee_details.name')" sortable :show-overflow-tooltip="true" />
            <el-table-column prop="uploaded_at" width="170" :label="$t('employee_details.upload_date')" sortable />
            <el-table-column width="75">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.download')" placement="top">
                            <el-button type="primary" size="mini" @click="handleDownload(scope.row.id)">
                                <fa-icon :icon="['fas', 'download']" />
                            </el-button>
                        </el-tooltip>
                        <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.delete')" placement="top">
                            <el-button type="danger" size="mini" @click="handleDelete(scope.row.id)">
                                <fa-icon :icon="['fas', 'trash']" fixed-width />
                            </el-button>
                        </el-tooltip>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>

        <InitialLoader v-if="$wait.is('loading.initial_*')" />

        <NoDataInformation :data="noDataInformation" waitKey="loading" />
    </div>
</template>
<script>
import API from './files.api';

export default {
    components: {
        ModalUpload: () => import(/* webpackChunkName: "Client/FilesModalUpload" */ './FilesModalUpload'),
    },

    props: {
        clientId: {
            type:    Number,
            default: null,
        },
    },

    data() {
        return {
            noDataInformation:      false,
            modalUploadFileVisible: false,
            filesData:              [],
        };
    },

    beforeCreate() {
        this.$wait.start('loading.initial_files');
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading.files');
            this.filesData = await API.getAll(this.clientId);
            this.noDataInformation = this.filesData.length;
            this.$wait.end('loading.files');
            this.$wait.end('loading.initial_files');
        },

        async showUploadModal() {
            this.modalUploadFileVisible = true;
        },

        async handleDownload(id) {
            window.location.href = await API.getDownloadURL(this.clientId, id);
        },

        async handleDelete(id) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  API.destroyURL(this.clientId, id),
                actionType: 'destroy',
            });
        },

        actionSuccess() {
            this.getAll();
            this.$emit('clientDataChanged');
        },
    },
};
</script>
